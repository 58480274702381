import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */
import DefaultLayout from "/working_dir/node_modules/gatsby-theme-docz/src/base/Layout.js";
import { Playground, Props } from 'docz';
import mattressIpsum from 'mattress-ipsum';
import { Accordion, AccordionItem } from '../../src';
import MediumBed from '../../src/Icons/MediumBed';
import PlushBed from '../../src/Icons/PlushBed';
import CustomAccordion from './helpers/CustomAccordion';
import Component from 'react-component-component';
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">



    <h1 {...{
      "id": "accordion"
    }}>{`Accordion`}</h1>
    <pre><code parentName="pre" {...{
        "className": "language-javascript"
      }}>{`import Accordion from '@mfrm/mfcl/Accordion'
`}</code></pre>
    <h2 {...{
      "id": "description"
    }}>{`Description`}</h2>
    <p>{`Accordion Component can be used to display a list of collapsable content panels holding either text or other React Components.`}</p>
    <h2 {...{
      "id": "properties"
    }}>{`Properties`}</h2>
    <Props of={Accordion} mdxType="Props" />
    <h2 {...{
      "id": "basic-usage"
    }}>{`Basic Usage`}</h2>
    <Playground __position={1} __code={'<Accordion\n  items={[\n    {\n      title: \'Title 1\',\n      id: \'title1\',\n      content: mattressIpsum(2),\n    },\n    { title: \'Title 2\', id: \'title2\', content: \'Less interesting content\' },\n  ]}\n/>'} __scope={{
      props,
      DefaultLayout,
      Playground,
      Props,
      mattressIpsum,
      Accordion,
      AccordionItem,
      MediumBed,
      PlushBed,
      CustomAccordion,
      Component,
      DefaultLayout,
      _frontmatter
    }} mdxType="Playground">
  <Accordion items={[{
        title: 'Title 1',
        id: 'title1',
        content: mattressIpsum(2)
      }, {
        title: 'Title 2',
        id: 'title2',
        content: 'Less interesting content'
      }]} mdxType="Accordion" />
    </Playground>
    <h2 {...{
      "id": "with-preview-text"
    }}>{`With Preview Text`}</h2>
    <Playground __position={2} __code={'<Accordion\n  items={[\n    {\n      preview: \'title 1 Description\',\n      title: \'Title 1\',\n      id: \'title1\',\n      content: mattressIpsum(2),\n    },\n    {\n      preview: \'more description\',\n      title: \'Title 2\',\n      id: \'title2\',\n      content: \'Less interesting content\',\n    },\n  ]}\n/>'} __scope={{
      props,
      DefaultLayout,
      Playground,
      Props,
      mattressIpsum,
      Accordion,
      AccordionItem,
      MediumBed,
      PlushBed,
      CustomAccordion,
      Component,
      DefaultLayout,
      _frontmatter
    }} mdxType="Playground">
  <Accordion items={[{
        preview: 'title 1 Description',
        title: 'Title 1',
        id: 'title1',
        content: mattressIpsum(2)
      }, {
        preview: 'more description',
        title: 'Title 2',
        id: 'title2',
        content: 'Less interesting content'
      }]} mdxType="Accordion" />
    </Playground>
    <h2 {...{
      "id": "with-set-initial-open"
    }}>{`With Set Initial Open`}</h2>
    <Playground __position={3} __code={'<Accordion\n  items={[\n    {\n      initialOpen: true,\n      title: \'Product Description\',\n      id: \'productDescription\',\n      content: mattressIpsum(2),\n    },\n    {\n      title: \'Questions & Answers\',\n      id: \'questionAnswer\',\n      content: \'Questions and Answers here\',\n    },\n    {\n      title: \'Reviews\',\n      id: \'reviews\',\n      content: \'Be the first to review here\',\n    },\n  ]}\n/>'} __scope={{
      props,
      DefaultLayout,
      Playground,
      Props,
      mattressIpsum,
      Accordion,
      AccordionItem,
      MediumBed,
      PlushBed,
      CustomAccordion,
      Component,
      DefaultLayout,
      _frontmatter
    }} mdxType="Playground">
  <Accordion items={[{
        initialOpen: true,
        title: 'Product Description',
        id: 'productDescription',
        content: mattressIpsum(2)
      }, {
        title: 'Questions & Answers',
        id: 'questionAnswer',
        content: 'Questions and Answers here'
      }, {
        title: 'Reviews',
        id: 'reviews',
        content: 'Be the first to review here'
      }]} mdxType="Accordion" />
    </Playground>
    <h2 {...{
      "id": "custom-accordion-content"
    }}>{`Custom Accordion Content`}</h2>
    <Playground __position={4} __code={'<CustomAccordion />'} __scope={{
      props,
      DefaultLayout,
      Playground,
      Props,
      mattressIpsum,
      Accordion,
      AccordionItem,
      MediumBed,
      PlushBed,
      CustomAccordion,
      Component,
      DefaultLayout,
      _frontmatter
    }} mdxType="Playground">
  <CustomAccordion mdxType="CustomAccordion" />
    </Playground>
    <h2 {...{
      "id": "with-optional-icon"
    }}>{`With Optional Icon`}</h2>
    <Playground __position={5} __code={'<Accordion\n  items={[\n    {\n      icon: <MediumBed />,\n      title: \'Title 1\',\n      id: \'title1\',\n      content: mattressIpsum(2),\n    },\n    {\n      icon: <PlushBed />,\n      title: \'Title 2\',\n      id: \'title2\',\n      content: \'Less interesting content\',\n    },\n  ]}\n/>'} __scope={{
      props,
      DefaultLayout,
      Playground,
      Props,
      mattressIpsum,
      Accordion,
      AccordionItem,
      MediumBed,
      PlushBed,
      CustomAccordion,
      Component,
      DefaultLayout,
      _frontmatter
    }} mdxType="Playground">
  <Accordion items={[{
        icon: <MediumBed mdxType="MediumBed" />,
        title: 'Title 1',
        id: 'title1',
        content: mattressIpsum(2)
      }, {
        icon: <PlushBed mdxType="PlushBed" />,
        title: 'Title 2',
        id: 'title2',
        content: 'Less interesting content'
      }]} mdxType="Accordion" />
    </Playground>
    <h2 {...{
      "id": "with-hidden-preview-text"
    }}>{`With Hidden Preview Text`}</h2>
    <Playground __position={6} __code={'<Accordion\n  hidePreview\n  items={[\n    {\n      preview: \'Example1, Example2\',\n      title: \'Title 1\',\n      id: \'title1\',\n      icon: <MediumBed />,\n      content: mattressIpsum(2),\n    },\n    {\n      preview: \'Example1, Example2\',\n      title: \'Title 2\',\n      id: \'title2\',\n      icon: <PlushBed />,\n      content: mattressIpsum(2),\n    },\n  ]}\n/>'} __scope={{
      props,
      DefaultLayout,
      Playground,
      Props,
      mattressIpsum,
      Accordion,
      AccordionItem,
      MediumBed,
      PlushBed,
      CustomAccordion,
      Component,
      DefaultLayout,
      _frontmatter
    }} mdxType="Playground">
  <Accordion hidePreview items={[{
        preview: 'Example1, Example2',
        title: 'Title 1',
        id: 'title1',
        icon: <MediumBed mdxType="MediumBed" />,
        content: mattressIpsum(2)
      }, {
        preview: 'Example1, Example2',
        title: 'Title 2',
        id: 'title2',
        icon: <PlushBed mdxType="PlushBed" />,
        content: mattressIpsum(2)
      }]} mdxType="Accordion" />
    </Playground>
    <h2 {...{
      "id": "using-accordionitem-children"
    }}>{`Using AccordionItem Children`}</h2>
    <Playground __position={7} __code={'<Accordion>\n  <AccordionItem title={\'Test\'} id={\'test_1\'} content={mattressIpsum(2)} />\n  <AccordionItem\n    initialOpen\n    title={\'Test 2\'}\n    id={\'test_2\'}\n    content={mattressIpsum(2)}\n  />\n</Accordion>'} __scope={{
      props,
      DefaultLayout,
      Playground,
      Props,
      mattressIpsum,
      Accordion,
      AccordionItem,
      MediumBed,
      PlushBed,
      CustomAccordion,
      Component,
      DefaultLayout,
      _frontmatter
    }} mdxType="Playground">
  <Accordion mdxType="Accordion">
    <AccordionItem title={'Test'} id={'test_1'} content={mattressIpsum(2)} mdxType="AccordionItem" />
    <AccordionItem initialOpen title={'Test 2'} id={'test_2'} content={mattressIpsum(2)} mdxType="AccordionItem" />
  </Accordion>
    </Playground>
    <h2 {...{
      "id": "using-accordionitem-as-single-item"
    }}>{`Using AccordionItem as Single Item`}</h2>
    <Playground __position={8} __code={'<Accordion singleItemAccordion>\n  <AccordionItem\n    title={\'Test Single\'}\n    id={\'test_1\'}\n    content={mattressIpsum(2)}\n  />\n</Accordion>\n<Accordion singleItemAccordion>\n  <AccordionItem\n    initialOpen\n    title={\'Test Single initial open\'}\n    id={\'test_1_initial_open\'}\n    content={mattressIpsum(2)}\n  />\n</Accordion>'} __scope={{
      props,
      DefaultLayout,
      Playground,
      Props,
      mattressIpsum,
      Accordion,
      AccordionItem,
      MediumBed,
      PlushBed,
      CustomAccordion,
      Component,
      DefaultLayout,
      _frontmatter
    }} mdxType="Playground">
  <Accordion singleItemAccordion mdxType="Accordion">
    <AccordionItem title={'Test Single'} id={'test_1'} content={mattressIpsum(2)} mdxType="AccordionItem" />
  </Accordion>
  <Accordion singleItemAccordion mdxType="Accordion">
    <AccordionItem initialOpen title={'Test Single initial open'} id={'test_1_initial_open'} content={mattressIpsum(2)} mdxType="AccordionItem" />
  </Accordion>
    </Playground>
    <h2 {...{
      "id": "with-horizontal"
    }}>{`With Horizontal`}</h2>
    <Playground __position={9} __code={'<Accordion\n  horizontal\n  items={[\n    {\n      preview: \'Example1, Example2\',\n      title: \'Title 1\',\n      id: \'title1\',\n      icon: <MediumBed />,\n      content: \'Content 1\',\n    },\n    {\n      preview: \'Example1, Example2\',\n      title: \'Title 2\',\n      id: \'title2\',\n      icon: <PlushBed />,\n      content: \'Content 2\',\n    },\n    {\n      preview: \'Example1\',\n      title: \'Title 3\',\n      id: \'title3\',\n      icon: <MediumBed />,\n      content: \'Content 3\',\n    },\n  ]}\n/>'} __scope={{
      props,
      DefaultLayout,
      Playground,
      Props,
      mattressIpsum,
      Accordion,
      AccordionItem,
      MediumBed,
      PlushBed,
      CustomAccordion,
      Component,
      DefaultLayout,
      _frontmatter
    }} mdxType="Playground">
  <Accordion horizontal items={[{
        preview: 'Example1, Example2',
        title: 'Title 1',
        id: 'title1',
        icon: <MediumBed mdxType="MediumBed" />,
        content: 'Content 1'
      }, {
        preview: 'Example1, Example2',
        title: 'Title 2',
        id: 'title2',
        icon: <PlushBed mdxType="PlushBed" />,
        content: 'Content 2'
      }, {
        preview: 'Example1',
        title: 'Title 3',
        id: 'title3',
        icon: <MediumBed mdxType="MediumBed" />,
        content: 'Content 3'
      }]} mdxType="Accordion" />
    </Playground>
    <h2 {...{
      "id": "force-close-separate-accordions"
    }}>{`Force Close Separate Accordions`}</h2>
    <Playground __position={10} __code={'<Component\n  initialState={{\n    currentIndex: null,\n  }}\n>\n  {({ setState, state }) => (\n    <>\n      <Accordion singleItemAccordion>\n        <AccordionItem\n          title={\'Test Single 3\'}\n          id={\'test_3\'}\n          content={mattressIpsum(2)}\n          openIndex={state.currentIndex}\n          onOpen={() => setState({ currentIndex: 0 })}\n          forceClose={0 === state.currentIndex}\n        />\n      </Accordion>\n      <Accordion singleItemAccordion>\n        <AccordionItem\n          title={\'Test Single 2\'}\n          id={\'test_2\'}\n          content={mattressIpsum(2)}\n          openIndex={state.currentIndex}\n          onOpen={() => setState({ currentIndex: 1 })}\n          forceClose={1 === state.currentIndex}\n        />\n      </Accordion>\n      <Accordion singleItemAccordion>\n        <AccordionItem\n          title={\'Test Single 1\'}\n          id={\'test_1\'}\n          content={mattressIpsum(2)}\n          openIndex={state.currentIndex}\n          onOpen={() => setState({ currentIndex: 2 })}\n          forceClose={2 === state.currentIndex}\n        />\n        1\n      </Accordion>\n    </>\n  )}\n</Component>'} __scope={{
      props,
      DefaultLayout,
      Playground,
      Props,
      mattressIpsum,
      Accordion,
      AccordionItem,
      MediumBed,
      PlushBed,
      CustomAccordion,
      Component,
      DefaultLayout,
      _frontmatter
    }} mdxType="Playground">
  <Component initialState={{
        currentIndex: null
      }} mdxType="Component">
    {({
          setState,
          state
        }) => <>
        <Accordion singleItemAccordion mdxType="Accordion">
          <AccordionItem title={'Test Single 3'} id={'test_3'} content={mattressIpsum(2)} openIndex={state.currentIndex} onOpen={() => setState({
              currentIndex: 0
            })} forceClose={0 === state.currentIndex} mdxType="AccordionItem" />
        </Accordion>
        <Accordion singleItemAccordion mdxType="Accordion">
          <AccordionItem title={'Test Single 2'} id={'test_2'} content={mattressIpsum(2)} openIndex={state.currentIndex} onOpen={() => setState({
              currentIndex: 1
            })} forceClose={1 === state.currentIndex} mdxType="AccordionItem" />
        </Accordion>
        <Accordion singleItemAccordion mdxType="Accordion">
          <AccordionItem title={'Test Single 1'} id={'test_1'} content={mattressIpsum(2)} openIndex={state.currentIndex} onOpen={() => setState({
              currentIndex: 2
            })} forceClose={2 === state.currentIndex} mdxType="AccordionItem" />
          1
        </Accordion>
      </>}
  </Component>
    </Playground>
    <h2 {...{
      "id": "accordion-item-properties"
    }}>{`Accordion Item Properties`}</h2>
    <Props of={AccordionItem} mdxType="Props" />

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      